.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  padding-top: 3.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-height {
  height: calc(100vh - 20px);
  padding-top: 3.5rem;
  width: 100%;
  overflow-x: hidden;
}

.offcanvas-internal {
  margin-top: 3.5rem;
}

@media only screen and (max-width: 575.98px) {
  .offcanvas-add-knowledge {
    width: 100% !important;
  }

  .spacer-sm {
    height: 3.5rem;
  }

  .max-height-sm {
    max-height: 60vh;
  }
}

@media screen and (min-width: 576px) {
  .offcanvas-add-knowledge {
    width: 70% !important;
  }

  .full-height-sm {
    height: calc(100vh - 20px);
    padding-top: 3.5rem;
    width: 100%;
    overflow-x: hidden;
  }
}

.add-knowledge-button {
  width: 12em !important;
}

.button-like-link {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font: inherit;
  padding: 0;
  margin: 0;
  display: inline;
}
